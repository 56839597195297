.hovereffect {
  text-align: center;
  cursor: default;
  width: 150px;
  height: 150px;
  position: relative;
  overflow: hidden;
}

.hovereffect .overlay {
  -webkit-transition: opacity .35s, -webkit-transform .35s;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  width: 80%;
  height: 80%;
  transition: opacity .35s, transform .35s;
  position: absolute;
  top: 10%;
  left: 10%;
  overflow: hidden;
  transform: scale(0, 1);
}

.hovereffect:hover .overlay {
  opacity: 1;
  filter: alpha(opacity= 100);
  transform: scale(1);
}

.hovereffect img {
  transition: all .35s;
  display: block;
  position: relative;
}

.hovereffect:hover img {
  -webkit-filter: brightness(.6);
}

.hovereffect h2 {
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  opacity: 0;
  filter: alpha(opacity= 0);
  -webkit-transition: opacity .35s, -webkit-transform .35s;
  background-color: #0000;
  padding: 1em 0;
  font-size: 17px;
  transition: opacity .35s, transform .35s;
  position: relative;
  transform: translate3d(0, -100%, 0);
}

.hovereffect a, hovereffect p {
  color: #fff;
  opacity: 0;
  filter: alpha(opacity= 0);
  -webkit-transition: opacity .35s, -webkit-transform .35s;
  padding: 1em 0;
  transition: opacity .35s, transform .35s;
  transform: translate3d(0, 100%, 0);
}

.hovereffect:hover a, .hovereffect:hover p, .hovereffect:hover h2 {
  opacity: 1;
  filter: alpha(opacity= 100);
  transform: translate3d(0, 0, 0);
}
/*# sourceMappingURL=index.539d53a4.css.map */
